import './ImageModal.css'

const ImageModal = ({ uniquePhotoID, token }) => {
  const dLink = `${window.location.origin}/hla/search?UniquePhotoId=${uniquePhotoID}&ImageOnly=1&token=${token}`;

  return (
    <>
      <a target="_blank" rel="noreferrer" className="bg-[#046A38] rounded text-white py-[6px] px-[20px] mobile-margin-right" href={dLink} > View </a>
    </>
  );
};

export default ImageModal;
