import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import {useForm} from 'react-hook-form'
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup"
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {pdf} from '@react-pdf/renderer';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faDownload } from "@fortawesome/free-solid-svg-icons";

import PortraitPDF, {ymd} from './pdf/Portrait_PDFBlob';
//import PortraitPDF1 from './pdf/Portrait_PDF1';

//import Orientation from './pdf/Orientation';
//import LandscapePDF from './pdf/Landscape_PDF';

const Score = (props) => {
  
  const {finalData: data, LAID, employeeName, branchEmail, employeePhone, customerEmail, customerName, customerPhone, lead_ID} = props.data

  const removeReadOnly = lead_ID !== null && lead_ID!=="";
  
  const turfDensity = data.TurfDensity
  const weedDensity = data.WeedDensity
  const tColor = data.TurfAvgColor
  const planName = data.RecommendedPlan;
  const isLawnDormant = data.IsLawnDormant;

  
  //eslint-disable-next-line
  Date.prototype.ymd = ymd()

    const [tcRating, setTCRating] = useState(1);
    const [mail, setMail] = useState(false)
    const [phone, setPhone] = useState('')
    const [ephone, setEmpPhone] = useState('')
    const [formattedEmpPhone, setFormattedEmpPhone] = useState('');
    const [formattedCustPhone, setFormattedCustPhone] = useState('');
   


    const fetchLeadData = async () => {
      try {
        const response= await fetch(`${process.env.REACT_APP_LAP_GETDATABY_LEADID_URL}?LeadId=${lead_ID}`)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };
    
    const fetchAIDData = async () => {
      try {
        const response= await fetch(`${process.env.REACT_APP_LAP_GETDATABY_AID_URL}?aid=${LAID}`)
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    };
   




    let showQR = false
    
    const moistures = data.MoisturePercentage.split(",")
    let moisture_vals = [], avg_moisture;
    
    for(const moist of moistures){
        if (moist !== "") moisture_vals.push(parseFloat(moist));
    }
    if(moisture_vals.length){
        avg_moisture =  parseFloat( ( (moisture_vals.reduce((partialSum, a) => partialSum + a, 0)) / moisture_vals.length ).toFixed(1) ) 
        if (avg_moisture <= 15 || avg_moisture >= 45)
            showQR = true
    }
    
  
 
    const variants = {
      enter: (direction) => { return { x: direction > 0 ? 1000 : -1000, opacity: 0 };},
      center: { zIndex: 1, x: 0, opacity: 1 },
      exit: (direction) => { return { zIndex: 0, x: direction < 0 ? 1000 : -1000, opacity: 0 }; }
    };

    const legendValues = {
      vals: ["0-1", "2-4", "5-6", "7-8", "9-10"],
      names: ["Poor", "Average", "Fair", "Good", "Excellent"]
    }
    
    const customToastClass = {        className: "w-[300px] text-[14px] sm:text-[16px] ml-[18vw] xs:-ml-[3vw]"    };
    
    const onSubmit = async(resultData) => {
      
      resultData.custPhone = resultData.custPhone.toString().replace(/\+1|\(|\)|[ ]|-|_|[a-zA-Z]/g,'')
      resultData.empPhone = resultData.empPhone.toString().replace(/\+1|\(|\)|[ ]|-|_|[a-zA-Z]/g,'')

      const pdfName = `LAWNREPORT_${new Date().ymd()}.pdf`
      
      const formData = new FormData()
      
      Object.keys(resultData).map(key => {
        if(["empPhone", "custPhone"].includes(key))
          resultData[key] = resultData[key].toString().replace(/\+1|\(|\)|[ ]|-|_|[a-zA-Z]/g,'')
        return formData.append(key, resultData[key])
      })
      formData.append('LawnAssessmentid', LAID)

      //generate pdf blob
      const pdfBlob = await pdf(<PortraitPDF  data={data}  tcRating={tcRating} showQR={showQR} result={resultData} />).toBlob()

      if(!mail){
        
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        
        try{
          //call post resultData api
          const response = await fetch(process.env.REACT_APP_LAP_POSTRESULTDATA_URL, requestOptions);

          if(response.status === 200){
              const outputResponse = await response.text();
              
              //download pdf
              const fileURL = URL.createObjectURL(pdfBlob);
              const alink = document.createElement('a');
              alink.href = fileURL;
              alink.download = pdfName;

              alink.click();
              
              toast.success(outputResponse.replaceAll('"',''), customToastClass)
          }
          else if(response.status === 400){
            let outputResponse = await response.json();
            outputResponse = "Error: Issue while saving the record.";
            toast.error(outputResponse, customToastClass)
            
          }
          else    toast.error("Error: "+response.statusText, customToastClass)
        }catch(err){
          toast.error("Some Error Occured!", customToastClass)//+err.message
        }
      }
      else{
        
        const requestOptions = {
            method: 'POST',
            body: formData
        };

        try{
          //call send_pdf_mail api 
        
          const response = await fetch(process.env.REACT_APP_LAP_POSTRESULTDATA_URL, requestOptions);
          
          if(response.status === 200){

              //download pdf
              const fileURL = URL.createObjectURL(pdfBlob);
              const alink = document.createElement('a');
              alink.href = fileURL;

              alink.download = pdfName;
              alink.click();

              // send mail from client
              const emailClient = `mailto:${resultData.custEmail}?subject=${encodeURIComponent('Your Lawn Assessment Report')}&body=${encodeURIComponent(`Hi ${resultData.custName},


Regards
TruGreen Team`)}`//&attachment=${pdfPath}`
              window.open(emailClient)

              //toast.success(outputResponse.replaceAll('"',''), customToastClass)
          }
          else if(response.status === 400){
              //const outputResponse = {Message: "PDF Error. Mail not Sent"};
              const outputResponse = await response.json();
              toast.error(outputResponse.Message, customToastClass)
          }
          else    toast.error("Error: "+response.statusText, customToastClass)
        }catch(err){
          toast.error("Some Error Occured!", customToastClass)//+err.message
        }
        
      }
      fetchLeadData();
      fetchAIDData();



    }

    const USPhone = `+1 (___) ___-____`

    const generateUSFomratPhone = (id, phone1) => {
      phone1 =  phone1.toString().replace(/\+1|\(|\)|[ ]|-|_|[a-zA-Z]/g,'')
      const phoneLength =  phone1.length;
      let newPhone = phone1.length === 0? "":USPhone.substring(0,4);

      switch(true){
        case (phoneLength <= 3): newPhone += `${ phone1}`; break;
        case (phoneLength >= 4 && phoneLength <= 6): newPhone += `${ phone1.substring(0,3)}) ${ phone1.substring(3)}`; break;
        case (phoneLength >= 7 && phoneLength <= 10): newPhone += `${ phone1.substring(0,3)}) ${ phone1.substring(3,6)}-${ phone1.substring(6)}`; break;
        default: newPhone =  phone1;
      }

      if(phoneLength === 10){
        if(id === "empPhone")  delete errors.empPhone
        else  delete errors.custPhone
      } 
      //else errors.custPhone = {message: "Enter a valid phone number"}
      return newPhone
    }

    const hnadleUSPhone = async(e) => {
      const USFormat = generateUSFomratPhone(e.target.id, e.target.value)
      if(e.target.id === "empPhone")  setEmpPhone(USFormat)
      else      setPhone(USFormat)
    }

    const resultFormSchema = yup.object().shape({ 
      // empName: yup.string().matches(/^[A-Za-z]+(\s*[A-Za-z]+)+\s*$/gms, "Enter a valid name").max(25, 'Value should not exceed 25 characters').required("Employee Name can't be empty").label("Employee Name"),
      empName: yup.string().matches(/^[A-Za-z0-9\s-]+$/gms, "Enter a valid name").max(50, 'Value should not exceed 50 characters').required("Employee Name can't be empty").label("Employee Name"),
      branchEmail: yup.string().email("Enter a valid email").matches(/^.+@.+\..+/, "Enter a valid email").required("Branch Email Can't be empty").label("Branch Email"),
      empPhone: yup.string().matches(/^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, {message:"Enter a valid phone number", excludeEmptyString:true}).typeError("Enter a valid phone number").label("Employee Phone"),

      // custName: yup.string().matches(/^[A-Za-z]+(\s*[A-Za-z]+)+\s*$/gms, "Enter a valid name").required("Customer Name can't be empty").label("Customer Name"),
      custName: yup.string().matches(/^[A-Za-z0-9\s-]+$/gms, "Enter a valid name").max(50, 'Value should not exceed 50 characters').required("Customer Name can't be empty").label("Customer Name"),
      custPhone: yup.string().matches(/^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, {message:"Enter a valid phone number", excludeEmptyString:true}).typeError("Enter a valid phone number").label("Customer Phone"),
      //custEmail: yup.string().email("Enter a valid email").matches(/^.+@.+\..+/, "Enter a valid email").required("Customer Email Can't be empty").label("Customer Email")
      custEmail: yup.string().typeError("Enter a valid email").matches(/^.+@.+\..+/, {message: "Enter a valid email", excludeEmptyString: true}).label("Customer Email")
    })

    const {register, handleSubmit, setValue, formState: {errors}, setFocus} = useForm({
        resolver: yupResolver(resultFormSchema)
    });

    const planDetails = {
      "TruComplete": {desc: "Our comprehensive lawn care plan"},
      "TruHealth": {desc: "Our advanced plan to protect and care for your lawn"},
      "TruMaintenance": {desc: "Our standard lawn plan that cares for your lawn and treats weeds"},
      "TruBasic": {desc: "Partial season lawn services that feed your lawn and treat for weeds"},
    }

    

  useEffect(() => {
    // Format phone numbers once and store in state
    const formatEmpPhone = generateUSFomratPhone('empPhone', employeePhone);
    const formatCustPhone = generateUSFomratPhone('custPhone', customerPhone);
    
     setFormattedEmpPhone(formatEmpPhone);
     setFormattedCustPhone(formatCustPhone);
 
     // Set values using setValue from react-hook-form
     if (customerName) setValue('custName', customerName);
     if (customerEmail) setValue('custEmail', customerEmail);
     if (formatCustPhone) setValue('custPhone', formatCustPhone);
     if (employeeName) setValue('empName', employeeName);
     if (branchEmail) setValue('branchEmail', branchEmail);
     if (formatEmpPhone) setValue('empPhone', formatEmpPhone);
    
    if(!Object.keys(errors).length)
      window.scrollTo(0, 600)

      
    if(document.getElementById("custPhone").value.length === 17)
      setFocus("custPhone")
      
    if(document.getElementById("empPhone").value.length === 17)
      setFocus("empPhone")
  
    
    switch(true){
      case (parseFloat(tColor) > 0 && parseFloat(tColor) < 2): setTCRating(1); break;
      case (parseFloat(tColor) >= 2 && parseFloat(tColor) < 5): setTCRating(2); break;
      case (parseFloat(tColor) >= 5 && parseFloat(tColor) < 7): setTCRating(3); break;
      case (parseFloat(tColor) >= 7 && parseFloat(tColor) < 9): setTCRating(4); break;
      case (parseFloat(tColor) >= 9 && parseFloat(tColor) <= 10 ): setTCRating(5); break;
      default: setTCRating(1); break;
    }
    fetchLeadData();
    fetchAIDData();

  }, [setTCRating, tColor, errors, setFocus, customerName, customerEmail, customerPhone, employeeName, branchEmail, employeePhone, formattedEmpPhone, formattedCustPhone])


  
    
  return (
    <motion.div className='border border-gray-300' key="result" //initial="enter" animate="center" exit="exit" 
    variants={variants}
    transition={{
        x: { type: "tween", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
    }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    //transition={{ duration: 1 }}
    >
    <div className="" >
        <ToastContainer position='bottom-right' style={{right: 0}} autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>

    {/* <PortraitPDF1 data={data} tcRating={tcRating}  showQR={showQR} result={{}}/> */}

    {/* {orientation === 'Landscape' ? (
        <LandscapePDF data={data} tcRating={tcRating}/>
    ) : (
        <PortraitPDF data={data} tcRating={tcRating}/>
    )} */}
    
    {/* <div className='h-[1794px] md:h-[974px] lg:h-[1025px] bg-[#F1F1F1]'> */}
    <div className='h-auto bg-[#F1F1F1]'>
        <div className='h-[218px] md:h-[228px] lg:h-[248px] w-full flex flex-col justify-start px-[16px] md:px-[40px] lg:px-[80px] py-[20px] md:py-[30px] bg-gradient-to-r from-[#046A38] to-[#00B140] ' >
          <div className='font-sspBold text-[18px] md:text-[24px] lg:text-[30px] text-white leading-[18px] md:leading-[32px]'>Lawn Assessment Application</div>
          
          <div className='w-full flex justify-start text-white text-[16px] lg:text-[18px] mt-[18px] lg:mt-[26px] h-[120px] md:h-[200px]'>
            <div className='flex flex-col justify-between items-start leading-[20px] w-[250px] md:w-[220px] font-sspReg tracking-[0.29px] md:tracking-[0.32px] lg:tracking-[0.36px]  mr-[5px]'>
                <div className='h-[20px]'>EMPLOYEE NUMBER</div>
                <div className='h-[20px]'>BRANCH NUMBER</div>
                <div className='h-[20px]'>GRASS TYPE</div>
                <div className='h-[20px]'>CUSTOMER ADDRESS</div>
                {/* <div>ZIP CODE</div> */}
            </div>

            <div className='flex flex-col justify-between items-start leading-[23px] w-full font-sspReg md:tracking-[0.32px] lg:tracking-[0.36px] -mt-[2px]'>
                <div className='flex h-[20px]'><div> - </div><div className='ml-[10px]' >{data.EmployeeNumber}</div></div>
                <div className='flex h-[20px]'><div> - </div><div className='ml-[10px]' >{data.BranchNumber}</div></div>
                <div className='flex h-[20px]'><div> - </div><div className='ml-[10px]' >{data.GrassType}</div></div>
                <div className='flex h-[20px]'><div> - </div><div className='ml-[10px] -mt-[1px]' >{data.CustomerAddress}</div></div>
                {/* <div> - <span className='ml-[10px]' >{data.PostalCode}</span> </div> */}
            </div>
          </div>
        </div>
          
        <div className='px-[16px] md:px-[40px] lg:px-[80px] flex flex-col ' >
          
          <div className='mt-[20px] md:mt-[30px]'>
            <p className='text-[18px] text-[#303236] font-sspBold'>ASSESSMENT RESULT</p>
            <div className='md:h-[240px] lg:h-[270px] flex flex-col md:flex-row justify-between mt-[14px] md:mt-[18px]'>
              
              <div className='w-[358] md:w-[180px] lg:w-[230px] rounded-md shadow-[0px_3px_6px_#00000029] bg-white mb-[20px] md:mb-0'>
                <div className='bg-[#046A38] h-[40px] rounded-t-md text-white text-[18px] md:text-[16px] lg:text-[18px] font-sspBold flex justify-center items-center'>TURF DENSITY</div>
                <div className='p-[15px] md:px-[10px] md:py-[20px] lg:p-[15px] flex justify-center md:flex-none'><img className='w-[200px] h-[200px] md:w-[160px] md:h-[160px] lg:w-[200px] lg:h-[200px]' src={require(`./image/Turf${turfDensity}.png`)} alt='turfDensity'/></div>
              </div>
              
              <div className='w-[358] md:w-[180px] lg:w-[230px] rounded-md shadow-[0px_3px_6px_#00000029] bg-white mb-[20px] md:mb-0'>
                <div className='bg-[#046A38] h-[40px] rounded-t-md text-white text-[18px] md:text-[16px] lg:text-[18px] font-sspBold flex justify-center items-center'>WEED DENSITY</div>
                <div className='p-[15px] md:px-[10px] md:py-[20px] lg:p-[15px] flex justify-center md:flex-none'><img className='w-[200px] h-[200px] md:w-[160px] md:h-[160px] lg:w-[200px] lg:h-[200px]' src={require(`./image/Weed${weedDensity}.png`)} alt='weedDensity'/></div>
              </div>
              
              <div className='w-[358] md:w-[296px] lg:w-[369px] rounded-md shadow-[0px_3px_6px_#00000029] bg-white'>
                <div className='bg-[#046A38] h-[40px] rounded-t-md text-white text-[18px] md:text-[16px] lg:text-[18px] font-sspBold flex justify-center items-center'>TURF COLOR</div>
                <div className='px-[0px] pr-[5px] py-[15px] md:px-[10px] md:py-[20px] lg:px-[15px] lg:py-[15px] flex justify-center'>
                  <img className='w-[200px] h-[200px] md:w-[160px] md:h-[160px] lg:w-[200px] lg:h-[200px]' src={require(`./image/TurfColor${tcRating}.png`)} alt='turfColor'/>
                  <div className='flex flex-col justify-between my-[24px] md:my-[12px] lg:my-[28px] ml-[4px] md:ml-[6px] lg:ml-[23px] w-[120px]'>
                  { [1, 2, 3, 4, 5].map((i) => {
                      return <div key={`legend_${i}`} className='flex text-[#046A38] text-[14px] font-sspReg leading-[18px]'>
                        <img src={require(`./image/legendIcon${i}.svg`)} alt={`legendIcon${i}`} />
                        <p className='ml-[6px] w-[28px] '>{legendValues.vals[i-1]}</p>
                        <p className='ml-[9px] md:ml-[6px]'>{legendValues.names[i-1]}</p>
                      </div>
                  }) }
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className=' mt-[15px] md:mt-[20px] flex flex-col items-center gap-6'>
            {/* <p className='text-[18px] text-[#303236] font-sspBold'>LAWN SCORE</p> */}
            
            {/* <div className='grid grid-cols-2 grid-flow-row gap-y-3 md:flex md:justify-between mt-[14px] md:mt-[18px]'> */}
              {/* <div className='w-[140px] md:w-[145px] lg:w-[180px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px]'>
                  <p className='text-[14px] lg:text-[16px]'>TURF DENSITY</p> <p className='text-[20px] lg:text-[24px]'>{data.TurfDensity}</p>
              </div>
              <div className='w-[182px] md:w-[145px] lg:w-[180px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px] -ml-[18px] md:-ml-[0px]'>
                  <p className='text-[14px] lg:text-[16px]'>WEED DENSITY</p> <p className='text-[20px] lg:text-[24px]'>{data.WeedDensity}</p>
              </div>
              <div className='w-[140px] md:w-[145px] lg:w-[180px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px]'>
                  <p className='text-[14px] lg:text-[16px]'>TURF COLOR </p> <p className='text-[20px] lg:text-[24px]'>{data.TurfAvgColor}</p>
              </div> */}

              {isLawnDormant && <div className="dormant text-[0.75rem] md:text-[0.8rem] leading-[16px] lg:leading-[18px]" style={{border: "none"}}>
                <fieldset className="rounded-md p-3 border border-[#8C8C8C] text-[#303236]" >
                  <legend className="float-none w-auto px-3 font-sspBold text-[1rem] text-[#303236]">No Color Rating Obtained</legend>
                  A color rating was not included as part of your lawn assessment, due to minimal growth occurring in lawn grasses currently. As temperatures warm up, fertilizer applications included in our recommended program will help promote a dark green color and improve the Turf Density in your lawn. Weed control applied will also help decrease the presence of weeds throughout the season.
                </fieldset>
              </div>}

              <div className='w-[242px] md:w-[296px] lg:w-[386px] h-[42px] font-sspReg flex justify-around items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px] -ml-[18px] md:-ml-[0px]'>
                  <p className='text-[18px] lg:text-[20px]'>TOTAL LAWN SCORE</p> <p className='text-[20px] lg:text-[24px]'>{data.TotalLawnScore*10}</p>
              </div>
            {/* </div> */}
          </div>
          
          <div className='mt-[20px] md:mt-[30px]'>
            <p className='text-[18px] text-[#303236] font-sspBold'>RECOMMENDED PROGRAM</p>
            <div className='h-auto md:h-[215px] w-full flex flex-col md:flex-row justify-start items-center mt-[14px] md:mt-[18px] bg-white rounded-[20px] p-[12px]'>
              
              {/* <div className='w-[191px] h-[191px] rounded-[20px] bg-emerald-100'></div> */}
              <img className='w-[334px] h-[234px] md:w-[191px] md:h-[191px]' src={require(`./image/PlanImage@2x.png`)} alt='planImage'/>
              
              <div className='flex flex-col justify-between h-[180px] md:h-[220px] lg:h-[200px] ml-0 md:ml-[32px] mt-[16px] md:mt-[0px] py-[16px] md:py-[28px] w-[334px] md:w-full px-[12px] md:px-0'>  
                <div className=''>
                  <p className='text-[#046A38] text-[28px] md:text-[30px] font-sspBold1 tracking-[0.6px] leading-[20px]'>{planName}</p>
                  <p className='text-[#046A38] text-[16px] md:text-[18px] font-sspReg mt-[8px]'>{planDetails[planName].desc}</p>
                </div>
                
                <div className='font-sspBold text-[14px] md:text-[18px] text-[#303236]'>
                Lawn Applications Included:<span className='ml-[14px]'>{data.LawnApplicationIncluded}</span>
                </div>
                
                <div className='font-sspBold text-[14px] md:text-[18px] text-[#303236]'>
                Expanded Services Included:<span className='ml-[14px]'>{data.ExpendedServiceIncluded}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
          

        <div className='w-full flex flex-col justify-around px-[10px] py-[10px] bg-white mt-[20px] md:mt-[40px] border-0' >
          
          <div className='w-full flex flex-col md:flex-row justify-center items-center text-[16px] lg:text-[18px] border-0'>
          
          <form onSubmit={handleSubmit(onSubmit)} className='border-0'>
            <div className='flex flex-col justify-between items-center leading-[20px] font-sspReg tracking-[0.29px] md:tracking-[0.32px] lg:tracking-[0.36px] border-0 w-[370px] md:w-[400px] lg:w-[500px]'>
                
              <div className='w-full md:w-[328px] lg:w-[400px] mt-[12px] px-[12px] md:px-0'>
                  <label className= 'font-sspReg text-[16px] lg:text-[16px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px]' htmlFor="empName">*EMPLOYEE NAME </label>
                  
                  <input type='text' name="empName" {...register("empName")} placeholder='Enter Employee Name' className={`border ${(errors.empName ?'border-[#FF0000]':'border-[#00B140]')} text-[14px] pl-[10px] w-full h-[36px] mt-[4px] md:mt-[4px] placeholder:italic tracking-[0.32px]`} id="empName" readOnly={removeReadOnly}/>
                  
                  {errors.empName && <div className='errorMsg1 font-sspReg'>{errors.empName?.message}</div>}
              </div>
              <div className='w-full md:w-[328px] lg:w-[400px] mt-[12px] px-[12px] md:px-0'>
                  <label className=' font-sspReg text-[16px] lg:text-[16px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px] ' htmlFor="branchEmail">*BRANCH EMAIL</label>
                  
                  <input type='text' inputMode='email' name="branchEmail" {...register("branchEmail")} placeholder='Enter Branch Email' className={`border ${(errors.branchEmail ?'border-[#FF0000]':'border-[#00B140]')} text-[14px] pl-[10px] w-full h-[36px] mt-[4px] md:mt-[4px] placeholder:italic tracking-[0.32px]`} id="branchEmail" readOnly={removeReadOnly}/>
                  
                  {errors.branchEmail && <div className='errorMsg1 font-sspReg'>{errors.branchEmail?.message}</div>}
              </div>

              <div className='w-full md:w-[328px] lg:w-[400px] mt-[12px] px-[12px] md:px-0'>
                  <label className='font-sspReg text-[16px] lg:text-[16px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px] ' htmlFor="empPhone">EMPLOYEE PHONE</label>
                  
                  <input type='tel' name="empPhone" {...register("empPhone")} placeholder={USPhone} value={ephone} maxLength={17} className={`border ${(errors.empPhone ?'border-[#FF0000]':'border-[#00B140]')} text-[14px] pl-[10px] w-full h-[36px] mt-[4px] md:mt-[4px] placeholder:italic tracking-[0.32px]`} id="empPhone" onChange={hnadleUSPhone}/>
                  
                  {errors.empPhone && <div className='errorMsg1 font-sspReg'>{errors.empPhone?.message}</div>}
              </div>

              <div className='w-full md:w-[328px] lg:w-[400px] mt-[12px] px-[12px] md:px-0'>
                  <label className= 'font-sspReg text-[16px] lg:text-[16px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px]' htmlFor="custName">*CUSTOMER NAME </label>
                  
                  <input type='text' name="custName" {...register("custName")} placeholder='Enter Customer Name' className={`border ${(errors.custName ?'border-[#FF0000]':'border-[#00B140]')} text-[14px] pl-[10px] w-full h-[36px] mt-[4px] md:mt-[4px] placeholder:italic tracking-[0.32px]`} id="custName" readOnly={removeReadOnly}/>
                  
                  {errors.custName && <div className='errorMsg1 font-sspReg'>{errors.custName?.message}</div>}
              </div>

              <div className='w-full md:w-[328px] lg:w-[400px] mt-[12px] px-[12px] md:px-0'>
                  <label className='font-sspReg text-[16px] lg:text-[16px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px] ' htmlFor="custPhone">CUSTOMER PHONE</label>
                  
                  <input type='tel' name="custPhone" {...register("custPhone")} placeholder={USPhone} value={phone} maxLength={17} className={`border ${(errors.custPhone ?'border-[#FF0000]':'border-[#00B140]')} text-[14px] pl-[10px] w-full h-[36px] mt-[4px] md:mt-[4px] placeholder:italic tracking-[0.32px]`} id="custPhone" onChange={hnadleUSPhone} readOnly={removeReadOnly}/>
                  
                  {errors.custPhone && <div className='errorMsg1 font-sspReg'>{errors.custPhone?.message}</div>}
              </div>

              <div className='w-full md:w-[328px] lg:w-[400px] mt-[12px] px-[12px] md:px-0'>
                  <label className= 'font-sspReg text-[16px] lg:text-[16px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px]' htmlFor="custEmail">CUSTOMER EMAIL </label>
                  
                  <input type='text' inputMode='email' name="custEmail" {...register("custEmail")} placeholder='Enter Customer Email' className={`border ${(errors.custEmail ?'border-[#FF0000]':'border-[#00B140]')} text-[14px] pl-[10px] w-full h-[36px] mt-[4px] md:mt-[4px] placeholder:italic tracking-[0.32px]`} id="custEmail"/>
                  
                  {errors.custEmail && <div className='errorMsg1 font-sspReg'>{errors.custEmail?.message}</div>}
              </div>

              <div className=''>
                <button type='submit' onClick={() => setMail(false)} className='w-[210px] h-[30px] md:w-[210px] md:h-[40px] cursor-pointer text-white text-[16px] md:text-[18px] bg-[#046A38] rounded-lg font-sspBold my-[30px] md:my-[30px] tracking-[0.36px] leading-[22px]' >
                  {/* <FontAwesomeIcon icon={faDownload} color='white'/> */}
                  <span className='ml-2'>Save & Generate PDF</span>
                </button>

                {/* <button type='submit' onClick={() => setMail(true)} className='w-[130px] h-[30px] md:w-[150px] md:h-[40px] cursor-pointer text-white text-[16px] md:text-[18px] bg-[#046A38] rounded-lg font-sspBold my-[30px] md:my-[40px] tracking-[0.36px] leading-[22px] ml-[30px] lg:ml-[50px]'> 
                  <FontAwesomeIcon icon={faEnvelope} color='white'/>
                  <span className='ml-2'> EMAIL</span>
                </button> */}
              </div>

            </div>
            </form>
            
            {showQR && <div className='flex justify-center items-center leading-[23px] w-[370px] md:w-[400px] lg:w-[500px] font-sspReg md:tracking-[0.32px] lg:tracking-[0.36px] px-[12px] md:px-0 border-0'>
              <img src={require(`./image/LawnQRcode.png`)} alt="watering practices" width={400}  />
            </div>}

          </div>
        </div>

        
        
        <div className='text-center relative '>  
          <Link to="/">
            <input type='button' className='w-[150px] h-[40px] md:w-[190px] md:h-[60px] cursor-pointer text-white text-[16px] md:text-[18px] bg-[#046A38] rounded-lg font-sspBold my-[30px] md:my-[40px] tracking-[0.36px] leading-[22px]' value="START OVER"/>
          </Link>

          <a className=' absolute right-[20px] top-[75px] md:top-[100px] font-sspReg text-[14px] md:text-[16px] underline' href="https://d2dolympus.trugreen.com/Sales/PartySearch" target='_blank' rel='noreferrer' >Sales Application </a>
        </div>
  
    </div>
    </motion.div>
  )
}

export default Score